h1 {
  color: #77bc43;
  font-family: Montserrat, sans-serif;
  font-weight: bold;
}

a, p {
  font-family: Montserrat, sans-serif;
  color: #495c67 !important;
}

nav div ul li a {
  color: #77bc43 !important;
}

nav div ul li .active {
  font-family: Montserrat, sans-serif;
  font-weight: bold;
  text-decoration: underline;
  color: #2b5134 !important;
}

.powered-by-div .powered-by-span {
  word-wrap: normal;
  color: #77bc43;
}

/*# sourceMappingURL=index.caf9171f.css.map */
